import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import {Team} from '../../../core/models/team';
import {TeamService} from '../../../core/services/team.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SelectedTeamStore {
  team$: BehaviorSubject<Team | null>;
  team: Team | null;
  isLoading$: BehaviorSubject<boolean>;
  isError$: BehaviorSubject<string | null>;

  constructor(private teamService: TeamService, private router: Router) {
    if (!this.team$) {
      this.team$ = new BehaviorSubject(null) as BehaviorSubject<Team | null>;
    }

    if (!this.isLoading$) {
      this.isLoading$ = new BehaviorSubject(false) as BehaviorSubject<boolean>;
    }

    if (!this.isError$) {
      this.isError$ = new BehaviorSubject(null)  as BehaviorSubject<string | null>;
    }
  }

  getTeam(): Observable<Team | null> {
    return this.team$.asObservable();
  }

  logoutTeam(): void {
    this.team = null;
    this.team$.next(null);
  }

  updateTeam(team: Team): void {
    this.team = {...this.team, ...team};
    this.team$.next(this.team);
  }

  resetTeam(): void {
    this.team = null;
    this.team$.next(this.team);
  }

  refreshTeam(uid: string): void {
    this.subscribeToGetTeam(uid);
  }

  getIsLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  getIsError(): Observable<string | null> {
    return this.isError$.asObservable();
  }

  private subscribeToGetTeam(uid: string): void {
    this.isLoading$.next(true);
    this.isError$.next(null);
    this.teamService
        .getTeam(uid)
        .pipe(
            finalize(() => {
              this.isLoading$.next(false);
            })
        )
        .subscribe(
            (team: Team) => {
              this.team = team;
              this.team$.next(this.team);
            },
            (error: any) => {
              this.router.navigate(['../']).then();
              this.isError$.next(
                  error.message.replace("GraphQL error:", "").trim()
              );
            }
        );
  }
}
