import { Injectable } from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {FirstSection} from "../models/first-section";
import {GenderChart} from "../models/gender-chart";
import {TeamChart} from "../models/team-chart";
import {EmployeeOnTime} from "../models/employee-on-time";
import {PositionChart} from "../models/position-chart";
import {MonthlyEmployee} from "../models/monthly-employee";
import {EmployeesTable} from "../models/employees-table";

@Injectable({
  providedIn: 'root'
})
export class GraphicsService {

  constructor(private apollo: Apollo) { }

  getEmployeesTeamsLeadersMetrics(teamUid: string | null = null): Observable<FirstSection> {
    const employeesTeamsLeadersMetrics = gql`
      query employeesTeamsLeadersMetrics($teamUid: String) {
        employeesTeamsLeadersMetrics(teamUid: $teamUid) {
          employeesCount
          teamsCount
          leadersCount
        }
      }
    `;
    return this.apollo
      .query<{ employeesTeamsLeadersMetrics: FirstSection }>({
        query: employeesTeamsLeadersMetrics,
        variables: {
          teamUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data.employeesTeamsLeadersMetrics;
        })
      );
  }

  getGenderChart(teamUid: string | null = null): Observable<GenderChart> {
    const genderChart = gql`
      query genderChart($teamUid: String) {
        genderChart(teamUid: $teamUid) {
          women {
            amount
            percent
          }
          men {
            amount
            percent
          }
          other {
            amount
            percent
          }
          withoutInfo {
            amount
            percent
          }
        }
      }
    `;
    return this.apollo
      .query<{ genderChart: GenderChart }>({
        query: genderChart,
        variables: {
          teamUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data.genderChart;
        })
      );
  }

  getTeamsChart(): Observable<TeamChart[]> {
    const teamsChart = gql`
      query teamsChart {
        teamsChart {
          percent
          team {
            name
            color
          }
          amount
        }
      }
    `;
    return this.apollo
      .query<{ teamsChart: TeamChart[] }>({
        query: teamsChart
      })
      .pipe(
        map(({ data }) => {
          return data.teamsChart;
        })
      );
  }

  getEmployeesOnTimeChart(month: number, year: number, teamUid: string | null = null): Observable<EmployeeOnTime[]> {
    const employeesOnTimeChart = gql`
      query employeesOnTimeChart($month: Int!, $year: Int! $teamUid: String) {
        employeesOnTimeChart(month: $month, year: $year teamUid: $teamUid) {
          percent
          amount
          employee {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
          }
        }
      }
    `;
    return this.apollo
      .query<{ employeesOnTimeChart: EmployeeOnTime[] }>({
        query: employeesOnTimeChart,
        variables: {
          month,
          year,
          teamUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data.employeesOnTimeChart;
        })
      );
  }

  getEmployeesAttendancesChart(month: number, year: number, teamUid: string | null = null): Observable<EmployeeOnTime[]> {
    const employeesAttendancesChart = gql`
      query employeesAttendancesChart($month: Int!, $year: Int! $teamUid: String) {
        employeesAttendancesChart(month: $month, year: $year teamUid: $teamUid) {
          percent
          amount
          employee {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
          }
        }
      }
    `;
    return this.apollo
      .query<{ employeesAttendancesChart: EmployeeOnTime[] }>({
        query: employeesAttendancesChart,
        variables: {
          month,
          year,
          teamUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data.employeesAttendancesChart;
        })
      );
  }

  getPositionsChart(teamUid: string | undefined): Observable<PositionChart[]> {
    const positionsChart = gql`
      query positionsChart($teamUid: String!) {
        positionsChart(teamUid: $teamUid) {
          percent
          amount
          position {
            name
          }
        }
      }
    `;
    return this.apollo
      .query<{ positionsChart: PositionChart[] }>({
        query: positionsChart,
        variables: {
          teamUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data.positionsChart;
        })
      );
  }

  getEmployeesByMonth(year: number): Observable<MonthlyEmployee[]> {
    const employeesByMonth = gql`
      query employeesByMonth($year: Int!) {
        employeesByMonth(year: $year) {
          employees
          month
        }
      }
    `;
    return this.apollo
      .query<{ employeesByMonth: MonthlyEmployee[] }>({
        query: employeesByMonth,
        variables: {
          year
        }
      })
      .pipe(
        map(({ data }) => {
          return data.employeesByMonth;
        })
      );
  }

  getGeoLocations(filters: any, scheduleOnly: boolean = true): Observable<EmployeesTable> {
    return this.apollo.query<{employeesTable: EmployeesTable}>({
      query: gql`
        query employeesTable(
          $substring: String
          $teamUid: String
          $unassigned: Boolean
          $skip: Int
          $limit: Int
          $sort: String
          $order: String
          $eventType: String
          $scheduleOnly: Boolean
        ) {
          employeesTable(
            substring: $substring
            teamUid: $teamUid
            unassigned: $unassigned
            skip: $skip
            limit: $limit
            sort: $sort
            order: $order
            eventType: $eventType
            scheduleOnly: $scheduleOnly
          ) {
            employees{
              uid
              firstName
              lastName
              email
              photo {
                url
              }
              lastLocation {
                latitude
                longitude
                timestamp
                uid
              }
              team {
                uid
                color
                name
                icon {
                  url
                }
              }
            }
            count
          }
        }
      `,
      variables: {...filters, scheduleOnly}
    }).pipe(
      map(({data}) => {
        return data.employeesTable;
      })
    )
  }
}
